import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';
import P from '../components/core/p';

const BlogPage = () => (
  <Layout>
    <Seo title="Blog" />
    <div>
      <P>Seems empty...</P>
    </div>
  </Layout>
);

export default BlogPage;
